import React from "react";
import Jumbotron from 'react-bootstrap/Jumbotron';
import ReactHtmlParser from 'react-html-parser';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import getDataJSON from "../SharedFunctions/SharedFunctions";
import {adjustTextColor} from "../SharedFunctions/SharedFunctions";
import config from "../../config"; 

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        clients: [],
        isClientsLoading: false,
        purl: '',
        fontColor: ''
    }
}

async componentDidMount() {
  //const { match: { params } } = this.props;
  this.setState({ isClientsLoading: true, purl: this.props.location.state.purl });


  // Get Client and Campaign Information
  let body= {
    "procname" : "procClientSelectByPURL",
    "paramcount" : "1",
    "param1" : this.props.location.state.purl
  }
  await getDataJSON(body)
  .then(json => {
    //console.log(json[0][0].SiteColor);
    this.setState({ clients: json[0], fontColor: adjustTextColor(json[0][0].SiteColor), isClientsLoading: false, 
    isCampaignActive: json[0][0].IsCampaignActive, isClientPlanActive: json[0][0].IsClientPlanActive });
  });
}

  renderResponseTitle() {
    return (
<div>
        <div style={{paddingLeft:'0px', marginTop: "20px"}}><h4 class="ql-editor">{this.state.clients["0"].WebHeaderMsg}</h4> </div>
<div style={{clear: 'both', width: '100%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
<table style={{ align: 'left', marginLeft: '10px', marginRight: 'auto', marginTop: '20px',
                        width: '95%', border: '0px solid black'}}>
<tbody>
    <tr >
    <td class="ql-editor">{ReactHtmlParser(this.state.clients["0"].WebDetailedMsg)}</td>
    </tr>

</tbody>
</table>
</div>
    );
}

  render () {
    const { clients, isClientsLoading} = this.state;

    if (isClientsLoading) {
      return <p>Loading ...</p>;
    }

    if (clients.length === 0) {
      return <p></p>
    } 


    return (
              <div style={{padding: '1px', width: '100%'}}  >  {/* backgroundColor: 'lightgrey', */}
                <table style={{ align: 'center', marginLeft: '0px', marginRight: '0px',
                                width: '100%', border: '0px solid black', background: this.state.clients["0"].SiteColor}}>
                    <tbody>
                        <tr>
                            <td>
                            <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                    width: '95%', border: '0px solid black'}}>
                                <tbody>
                                <tr style={{width:'100%', color: this.state.fontColor, border: '0px solid black', verticalAlign: 'middle', background: this.state.clients["0"].SiteColor }} >
                                    <td style={{width:'50%', verticalAlign: 'middle'}}>
                                        <img  border="0px solid black" height="100px" style={{padding: "10px"}}
                                        src={"https://" + config.s3.BUCKET + ".s3.amazonaws.com/" + 
                                                "public/RRRClientLogo/Event_" + this.state.clients["0"].EventID + 
                                                "_logo.png"}  alt='Campaign Logo'/>
                                        <ul style={{display:'inline-block', listStyleType: "none"}}>
                                            <li style={{height: '24px',fontFamily: 'Ebrima',fontSize:'24px',fontWeight: '500'}}>{this.state.clients["0"].ClientName}</li>
                                        </ul>
                                    </td>
                                    <td style={{width:'50%', align:'right', verticalAlign: 'middle', textAlign:'right'}}>
                                        <ul style={{display:'inline-block', listStyleType: "none", paddingTop:'10px'}}>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].ContactName}</li>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].Email}</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                width: '95%', border: '0px solid black'}}>
                    <tbody>
                        <tr>    
                            <td>
                                <div>
                                    {this.renderResponseTitle()}
                                </div>
                                <br/>
                                <div style={{width: '100%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <div style={{  display: 'flex', justifyContent: 'center'}}>
                <Jumbotron style={{color: '#145212', backgroundColor: '#ADEFAB', marginLeft:'20px', marginRight:'20px', maxWidth:'500px'}}>
                  <div style={{textAlign:'center'}}><h1>Thank you!</h1></div>
                  <p>
                    Your submission has been recorded and is now being processed.
                    We appreciate your response!
                  </p>
                  <p>
                    {/* <Button variant="primary">Learn more</Button> */}
                  </p>
                </Jumbotron>
                </div>
                <br/>
                <br/>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                <br/>
                <div style={{clear:'both', textAlign: 'center', align: 'center'}}>
                        <table align='center' style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '40px',
                                        width: '65%', border: '1px solid black'}}>
                            <tbody>
                                <tr >
                                    <td class="ql-editor">{ReactHtmlParser(this.state.clients["0"].WebFooterMessage)}</td>
                                </tr>
                                <tr >
                                    <td class="ql-editor">{ReactHtmlParser(this.state.clients["0"].RecallHotline)}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

              </div>
    );
  }
}

export default Confirmation;