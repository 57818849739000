import React from "react";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import {Form, Container, Row, Col} from 'react-bootstrap';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import getDataJSON from "../SharedFunctions/SharedFunctions";
import Spinner from 'react-bootstrap/Spinner';import {adjustTextColor} from "../SharedFunctions/SharedFunctions";import config from "../../config"; 

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        clients: [],
        isClientsLoading: false,
        purl: '',
        showConfirmMessage: false,
        showCancelledMessage: false,
        isSubmitting: false
    }

    // This binds the eventHandler to its component instance
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
}

async componentDidMount() {
    const { match: { params } } = this.props;
    this.setState({ isClientsLoading: true, purl: params.purl });


    // Get Client and Campaign Information
    let body= {
        "procname" : "procClientSelectByPURL",
        "paramcount" : "1",
        "param1" : params.purl
    }
    await getDataJSON(body)
    .then(json => this.setState({ clients: json[0], isClientsLoading: false }));
}

handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true});
    this.insertUnsubscribedEmail();

}

handleCancel(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true});
    this.setState({ isSubmitting: false, result : null, showCancelledMessage: true})  
}


async insertUnsubscribedEmail() {

    let body= {
        "procname" : "procUnsubscribedEmailInsert",
        "paramcount": "2", 
        "param1": this.state.clients["0"].Email,
        "param2": this.state.clients["0"].ClientID
        }
    
    await getDataJSON(body)
    .then(json => {
        this.setState({ isSubmitting: false, result : json, showConfirmMessage: true});
    });
    //testing only
    //this.setState({ isSubmitting: false, result : null, showConfirmMessage: true});

}

renderConfirm () {
    return (
        <div style={{  display: 'flex', justifyContent: 'left'}}>
        <Jumbotron style={{color: '#231f20', backgroundColor: '#FFFFFF', marginLeft:'20px', marginRight:'20px'}}>
          <div style={{textAlign:'left'}}><h1>You've been unsubscribed!</h1></div>
          <p style={{textAlign:'left'}}>
            <br/>Your notification preferences have been recorded. <br/>You will not receive future communications.
          </p>
        </Jumbotron>
        </div>
    );
}

renderCancel () {
    return (
        <div style={{  display: 'flex'}}>
        <Jumbotron style={{color: '#231f20', backgroundColor: '#FFFFFF', marginLeft:'20px', marginRight:'20px'}}>
          <div style={{textAlign:'left'}}><h1></h1></div>
          <p style={{textAlign:'left'}}>
            Action cancelled. <br/>No changes have been made to your notification preferences.
          </p>
        </Jumbotron>
        </div>
    );
}


  render () {
    const { clients, isClientsLoading} = this.state;

    if (isClientsLoading) {
      return <p>Loading ...</p>;
    }

    if (clients.length === 0) {
      return <p></p>
    } 


    return (
              <div style={{padding: '1px', width: '100%'}}  > 
                <table style={{ align: 'center', marginLeft: '0px', marginRight: '0px',
                                width: '100%', border: '0px solid black', background: this.state.clients["0"].SiteColor}}>
                    <tbody>
                        <tr>
                            <td>
                            <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                    width: '95%', border: '0px solid black'}}>
                                <tbody>
                                <tr style={{width:'100%', color: this.state.fontColor, border: '0px solid black', verticalAlign: 'middle', background: this.state.clients["0"].SiteColor }}>
                                    <td style={{width:'50%',verticalAlign: 'middle'}}>
                                        <img  border="0px solid black" height="100px" style={{padding: "10px",height: "60px"}}
                                        src={"https://" + config.s3.BUCKET + ".s3.amazonaws.com/" + 
                                        "public/RRRClientLogo/Event_" + this.state.clients["0"].EventID + 
                                        "_logo.png"}  alt='Campaign Logo'/>
                                        <ul style={{display:'inline-block', listStyleType: "none", paddingTop:'10px'}}>
                                            <li style={{height: '24px',fontFamily: 'Ebrima',fontSize:'24px',fontWeight: '500'}}>{this.state.clients["0"].ClientName}</li>
                                        </ul>
                                    </td>
                                    <td style={{width:'50%', align:'right', verticalAlign: 'middle', textAlign:'right'}}>
                                        <ul style={{display:'inline-block', listStyleType: "none",paddingTop: "10px"}}>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].ContactName}</li>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].Email}</li>
                                        </ul>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br/>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width:'95%', marginBottom:'10px'}}><h4>Unsubscribe</h4> </div>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>

                {this.state.showConfirmMessage === true && 
                    <div>
                        {this.renderConfirm()}
                    </div>
                }

                {this.state.showCancelledMessage === true && 
                    <div>
                        {this.renderCancel()}
                    </div>
                }

                {this.state.showConfirmMessage !== true && this.state.showCancelledMessage !== true && 
                    <div style={{textAlign:'left'}}>
                        <br/>
                        <Form onSubmit={this.handleSubmit}>
                            <div style={{marginLeft: 'auto', marginRight: 'auto', width:'100%'}}>
                            <Container style={{paddingTop:"10px",marginLeft: '30px', marginRight: 'auto', width:'95%'}}>
                                <Row style={{paddingBottom:"30px"}}>
                                    <Col style={{align:'left'}}>I want to unsubscribe from all future email notifications sent through the Notification Solutions system on behalf of {this.state.clients["0"].ClientName}.</Col>
                                </Row>
                                <Row style={{paddingBottom:"30px"}}>
                                    <Col style={{align:'left'}}>
                                        <Button variant="primary" type="submit" >
                                            <Spinner hidden={!this.state.isSubmitting}
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span>&nbsp;Confirm&nbsp;</span>                                        
                                        </Button>
                                        <Button variant="default" type="button"   onClick={this.handleCancel} style={{color:"#007fff"}}>
                                            <Spinner hidden={!this.state.isSubmitting}
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span>&nbsp;Cancel&nbsp;</span>                                        
                                        </Button>

                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </Form>
                    </div>
                }

                <br/>
                <br/>
              </div>
    );
  }
}

export default Unsubscribe;