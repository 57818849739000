import React from "react";

class About extends React.Component {
  render () {
    return (
              <div style={{padding: '1px', width: '100%'}}  > 
                <table style={{ align: 'center', marginLeft: '0px', marginRight: '0px',
                                width: '100%', border: '0px solid black', background: '#007dbc'}}>
                    <tbody>
                        <tr>
                            <td>
                            <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                    width: '95%', border: '0px solid black'}}>
                                <tbody>
                                <tr style={{width:'100%', color: 'white', border: '0px solid black', verticalAlign: 'middle', background: '#007dbc' }} >
                                    <td style={{width:'50%',verticalAlign: 'middle'}}>
                                        <img  border="0px solid black" height="100px" style={{padding: "10px",height: "60px"}}
                                        src={require('../../images/SDWK_PrimaryLogo_White.png')}  alt='Sedgwick Logo'/>
                                        <ul style={{display:'inline-block', listStyleType: "none", paddingTop:'10px'}}>
                                            <li style={{height: '24px',fontFamily: 'Ebrima',fontSize:'24px',fontWeight: '500'}}></li>
                                        </ul>
                                    </td>
                                    <td style={{width:'50%', align:'right', verticalAlign: 'middle', textAlign:'right'}}>
                                        <ul style={{display:'inline-block', listStyleType: "none",paddingTop: "10px"}}>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}></li>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}></li>
                                        </ul>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br/>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width:'95%', marginBottom:'10px'}}><h4>Notiﬁcation Solutions™</h4> </div>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%', height: '6px', background: '#007dbc'}}>&nbsp;</div> {/* #00956e */}
                
                <br/>

{/* 
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%', textAlign:'left', justifyContent: 'left'}}>
                  This is the Notification Solutions™ response website. Contacts will not see this page. It is being used for testing only.
                  <br/>                
                  <br/>                
                  <ul style={{listStyle: 'none'}}>
                    <li style={{padding:'0px'}}>
                      <b><Link to="/b21dcf10f3c011e9975e0aecb35d0292">Link to ContactID 1002 (Acme Corp)</Link></b>
                    </li>
                    <li style={{padding:'0px'}}>
                      <b><Link to="/443066a8f54211e9975e0aecb35d0292">Link to ContactID 1603 (Our First RRR Customer)</Link></b>
                    </li>
                  </ul>
                </div>

 */}

                <br/>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%'}}>{`Copyright © Sedgwick ${new Date().getFullYear()}`}</div>
              </div>
    );


  }
}

export default About;