import React from "react";
import config from "../../config";


export default async function getDataJSON(Body, funcName='getdatajson'){

  config.apiGateway.OBJ.body = JSON.stringify(Body);
  //console.log(config.apiGateway.OBJ);

  const response = await fetch(config.apiGateway.URL + config.apiGateway.ENV + funcName,
                {...config.apiGateway.OBJ})
  .then(res => res.json())
  .catch(error => console.log(error))

  return response;
  };

  export function adjustTextColor(color) {
    var brightness;
    if(color===undefined){
      return ("#FF0000");
    }

    // Call lightOrDark function to get the brightness (light or dark)
    brightness = lightOrDark(color);

    if(brightness == 'dark') {
      return('#FFFFFF');
    } else {
      return('#000000');
    }

  };
  
  export function lightOrDark(color) {
    var r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
  
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  
      r = color[1];
      g = color[2];
      b = color[3];
    } 
    else {
  
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'
      )
               );
  
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
  
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  
    // Using the HSP value, determine whether the color is light or dark
    if (hsp>180.0) {
      return 'light';
    } 
    else {
      return 'dark';
    }
  };


