/* DEV */
const dev = {
  apiRegion: "us-east-2",
  apiUrl: "https://92eyo7p0l7.execute-api.us-east-2.amazonaws.com/",
  apiEnv: "Dev/",
  apiKey: "PBOIOPEzZ16gZT1PwEr8z3yzzsWB3A3p88cj8iY8",
  s3Region: "us-east-2",
  s3EventContentBucket: "srcl-selfserve-eventcontent-dev"
}

/* QA */
const qa = {
  apiRegion: "us-east-2",
  apiUrl: "https://5bwuvtzrj4.execute-api.us-east-2.amazonaws.com/",
  apiEnv: "QA/",
  apiKey: "ZESKLrlNHV19MjmxlrliX8PkVPFrDbyG89K4UOls",
  s3Region: "us-east-2",
  s3EventContentBucket: "scmsbp-notifyhub-qa-eventcontent"
}

/* PROD */
const prod = {
  apiRegion: "us-east-2",
  apiUrl: "https://4lvherfy5m.execute-api.us-east-2.amazonaws.com/",
  apiEnv: "PROD/",
  apiKey: "5w2uN9Z6Dg1IKQlu021bY9r1YCgDoWgI4aWkOCrw",
  s3Region: "us-east-2",
  s3EventContentBucket: "scmsbp-notifyhub-prod-eventcontent"
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : (process.env.REACT_APP_STAGE === 'qa' ? qa : dev);

export default {
  s3: {
    REGION: config.s3Region,//"us-east-2",
    BUCKET: config.s3EventContentBucket //"srcl-selfserve-eventcontent-dev",
  },
  apiGateway: {
    REGION: config.apiRegion,//"us-east-2",
    URL: config.apiUrl,//"https://92eyo7p0l7.execute-api.us-east-2.amazonaws.com/",
    ENV: config.apiEnv,//"Dev/",
    OBJ : {
      mode: 'cors',
      method: 'POST',
      headers: 
      {
          'Access-Control-Allow-Origin': '*', 
          'Access-Control-Allow-Headers': 'Content-Type',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': config.apiKey//'PBOIOPEzZ16gZT1PwEr8z3yzzsWB3A3p88cj8iY8'
      }},
      body:''
    
  }
};

