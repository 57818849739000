import React from "react";
import Jumbotron from 'react-bootstrap/Jumbotron';
import ReactHtmlParser from 'react-html-parser';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import getDataJSON from "../SharedFunctions/SharedFunctions";
import {adjustTextColor} from "../SharedFunctions/SharedFunctions";
import config from "../../config"; 

class NoAction extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        clients: [],
        isClientsLoading: false,
        purl: '',
        fontColor: ''
    }
}

async componentDidMount() {
  //const { match: { params } } = this.props;
  this.setState({ isClientsLoading: true, purl: this.props.location.state.purl });


  // Get Client and Campaign Information
  let body= {
    "procname" : "procClientSelectByPURL",
    "paramcount" : "1",
    "param1" : this.props.location.state.purl
  }
  await getDataJSON(body)
  .then(json => {
    //console.log(json[0][0].SiteColor);
    this.setState({ clients: json[0], fontColor: adjustTextColor(json[0][0].SiteColor), isClientsLoading: false, 
    isCampaignActive: json[0][0].IsCampaignActive, isClientPlanActive: json[0][0].IsClientPlanActive });
  });
}

  render () {
    const { clients, isClientsLoading} = this.state;

    if (isClientsLoading) {
      return <p>Loading ...</p>;
    }

    if (clients.length === 0) {
      return <p></p>
    } 


    return (
              <div style={{padding: '1px', width: '100%'}}  >  {/* backgroundColor: 'lightgrey', */}
                <table style={{ align: 'center', marginLeft: '0px', marginRight: '0px',
                                width: '100%', border: '0px solid black', background: this.state.clients["0"].SiteColor}}>
                    <tbody>
                        <tr>
                            <td>
                            <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                    width: '95%', border: '0px solid black'}}>
                                <tbody>
                                <tr style={{width:'100%', color: this.state.fontColor, border: '0px solid black', verticalAlign: 'middle', background: this.state.clients["0"].SiteColor }} >
                                    <td style={{width:'50%', verticalAlign: 'middle'}}>
                                        <img  border="0px solid black" height="100px" style={{padding: "10px"}}
                                        src={"https://" + config.s3.BUCKET + ".s3.amazonaws.com/" + 
                                                "public/RRRClientLogo/Event_" + this.state.clients["0"].EventID + 
                                                "_logo.png"}  alt='Campaign Logo'/>
                                        <ul style={{display:'inline-block', listStyleType: "none"}}>
                                            <li style={{height: '24px',fontFamily: 'Ebrima',fontSize:'24px',fontWeight: '500'}}>{this.state.clients["0"].ClientName}</li>
                                        </ul>
                                    </td>
                                    <td style={{width:'50%', align:'right', verticalAlign: 'middle', textAlign:'right'}}>
                                        <ul style={{display:'inline-block', listStyleType: "none", paddingTop:'10px'}}>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].ContactName}</li>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].Email}</li>
                                        </ul>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <br/>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width:'95%', marginBottom:'10px'}}><h4>No Action Required</h4> </div>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>

                <div style={{  display: 'flex', justifyContent: 'left'}}>
                  <Jumbotron style={{color: '#231f20', backgroundColor: '#FFFFFF', marginLeft:'20px', marginRight:'20px'}}>
                    <div style={{textAlign:'left'}}><h1></h1></div>
                    <p style={{textAlign:'left'}}>
                      No action or response is required at this point in time. 
                      <br/>We appreciate your effort in this matter!
                    </p>
                  </Jumbotron>
                </div>

              </div>
    );
  }
}

export default NoAction;