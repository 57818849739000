import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { 
    BrowserRouter as Router, 
    Switch, 
    Route //, 
    //Link 
} from "react-router-dom";
import './index.css';
import App from './components/app/app.jsx';
import About from './components/about/about.jsx';
import Confirmation from './components/confirmation/confirmation.jsx';
import NoAction from './components/noaction/noaction.jsx';
import Unsubscribe from './components/unsubscribe/unsubscribe.jsx';
import UnsubscribeOneClick from './components/unsubscribeOneClick/unsubscribeOneClick.jsx';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import Material from './components/app/material.jsx';


const routing = (
<Router>
    <div>
        <Switch>
            <Route path="/" exact component={About} />
            <Route path="/confirmation" exact component={Confirmation} />
            <Route path="/noaction" exact component={NoAction} />
            <Route path="/:purl" exact component={App} />
            <Route path="/unsubscribe/:purl" exact component={Unsubscribe} />
            <Route path="/unsubscribeOneClick/:purl" exact component={UnsubscribeOneClick} />
            <Route path="/:purl/:source" exact component={App} />
            <Route path="/material" exact component={Material} />
        </Switch>
    </div>
</Router>
)

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

