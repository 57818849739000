import React from 'react';
import 'react-table/react-table.css'
import './app.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Redirect
  } from "react-router-dom";
import MyButton from '../myButton/myButton.jsx'
import isPosInt from '../../utils/isPosInt.js'
import ReactHtmlParser from 'react-html-parser';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import {adjustTextColor} from "../SharedFunctions/SharedFunctions";
import getDataJSON from "../SharedFunctions/SharedFunctions";
import config from "../../config"; 


function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* Modal heading */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:'center', minWidth:'95%', minHeight:'95%'}}>
          <img onClick={props.onHide} width="95%" border="1px solid black" src={props.modalImage}  alt='Product Media' />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

class App extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            clients: [],
            allClients: [],
            products: [],
            lookupDetails: [],
            eventLookupDetails: [],
            productImages: [],
            productVideos: [],
            eventQuestions: [],
            addedItems: [],
            isClientsLoading: false,
            isProductsLoading: false,
            isLookupDetailsLoading: false,
            isEventQuestionsLoading: false,
            isEventQuestionsLookupLoading: false,
            isProductImagesLoading: false,
            isProductVideosLoading: false,
            isSubmitting: false,
            clientID: 0,
            purl: '',
            redirectToReferrer: false,
            modalShow: false, 
            setModalShow: false,
            modalImage: '',
            invalidSubmitCount: 0,
            isCampaignActive: 1,
            isClientPlanActive: 1,
            fontColor: '',
            invalidQuestionAnswerCount: 0,
            validated:  false,
            responseSource: 'Email'
        }
    
        // This binds the eventHandler to its component instance
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddResponseItem = this.handleAddResponseItem.bind(this);
        this.removeResponseItem = this.removeResponseItem.bind(this);
        this.clearResponseFields = this.clearResponseFields.bind(this);
       
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({ isClientsLoading: true, isProductsLoading: true, isLookupDetailsLoading: true, isEventQuestionsLoading: true, 
            isProductImagesLoading: true, isProductVideosLoading: true, isEventQuestionsLookupLoading: true, purl: params.purl });

        // if a source parameter exists then this page was initiated from a text message via the redirectpurl page in the portal
        if(params.source) {
            this.setState({ responseSource: 'Sms' });
        }

        // Get Client and Campaign Information
        let body= {
            "procname" : "procClientSelectByPURL",
            "paramcount" : "1",
            "param1" : params.purl              
        }
        await getDataJSON(body)
        .then(json => {
            //console.log(json[0][0].SiteColor);
            this.setState({ clients: json[0], fontColor: adjustTextColor(json[0][0].SiteColor), isClientsLoading: false, 
            isCampaignActive: json[0][0].IsCampaignActive, isClientPlanActive: json[0][0].IsClientPlanActive });
        
        });
        

        // Get Questions
        body= {
            "procname" : "procEventQuestionSelectByPURL",
            "paramcount" : "1",
            "param1" : params.purl           
        }
        await getDataJSON(body)
        .then(async(json) => 
        {
            this.setState({ eventQuestions: json[0], isEventQuestionsLoading: false });

            // Get Product Lookup Details
            body= {
                "procname" : "procLookupSelectForCampaignByPURL",
                "paramcount" : "1",
                "param1" : params.purl
            }
            await getDataJSON(body)
            .then(async(json) => 
            {
                // First set the payload to a state array                        
                let qsts = [...this.state.eventQuestions];
                let lkupdets = [...json[0]];
                let newEventQuestions = [];                

                // Then, for every question, get lookups
                qsts.forEach (qst => {
                    //newEventQuestions.push(qst);  // there is no need to push this to array yet - do this after adding Lookup
                    let newLookups = [];
                    let tempNewLookup = {};

                    // Model
                    lkupdets.forEach(lkupdet => {
                        tempNewLookup = {};
                        // if LookupID matches, add to object
                        if (qst.LookupID === lkupdet.LookupID) {
                            tempNewLookup = lkupdet;
                            newLookups.push(tempNewLookup);
                        }
                    });
                    
                    qst["Lookup"] = newLookups;
                    newEventQuestions.push(qst);
                    //newEventQuestions[0]["Lookup"] = newLookups;   // old, no longer do this way
                    newLookups = [];
                });

                // Finally, set lookup load complete to allow rendering to complete
                this.setState({ eventQuestions: newEventQuestions, eventLookupDetails: json[0], isEventQuestionsLookupLoading: false });
            });
        });

        // Get Product Information
        body= {
            "procname" : "procProductSelectByPURL",
            "paramcount" : "1",
            "param1" : params.purl
        }
        await getDataJSON(body)
        .then(async(json) => 
            {
                this.setState({ products: json[0], isProductsLoading: false })

                // Get Product Lookup Details
                body= {
                    "procname" : "procLookupSelectForProductsByPURL",
                    "paramcount" : "1",
                    "param1" : params.purl
                }
                await getDataJSON(body)
                .then((json) => 
                    {
                        // First set the payload to a state array                        
                        let prds = [...this.state.products];
                        let lkupdets = [...json[0]];
                        let newProducts = [];

                        // Then, for every product, go thru each of 9 possible questions and get lookups
                        prds.forEach (prd => {
                            let newLookups = [];
                            let tempNewLookup = {};
                            
                            // Model
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for Model Number, add to object
                                if (prd.ModelLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["ModelLookup"] = newLookups;
                            newLookups = [];

                            // Serial
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for Serial Number, add to object
                                if (prd.SerialLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["SerialLookup"] = newLookups;
                            newLookups = [];

                            // BatchLot
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for BatchLot Number, add to object
                                if (prd.BatchLotLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["BatchLotLookup"] = newLookups;
                            newLookups = [];

                            // NDC
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for NDC Number, add to object
                                if (prd.NDCLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["NDCLookup"] = newLookups;
                            newLookups = [];

                            // UPC
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for UPC Number, add to object
                                if (prd.UPCLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["UPCLookup"] = newLookups;
                            newLookups = [];

                            // PartNumber
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for Part Number, add to object
                                if (prd.PartNumberLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["PartNumberLookup"] = newLookups;
                            newLookups = [];

                            // ExpDate
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for Exp Date, add to object
                                if (prd.ExpDateLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["ExpDateLookup"] = newLookups;
                            newLookups = [];

                            // DateCode
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for Date Code, add to object
                                if (prd.DateCodeLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["DateCodeLookup"] = newLookups;
                            newLookups = [];

                            // Qty
                            lkupdets.forEach(lkupdet => {
                                tempNewLookup = {};
                                // if LookupID matches for Qty, add to object
                                if (prd.QtyLookupID === lkupdet.LookupID) {
                                    tempNewLookup = lkupdet;
                                    newLookups.push(tempNewLookup);
                                }
                            });
                            prd["QtyLookup"] = newLookups;
                            newLookups = [];

                            newProducts.push(prd);

                        });                
                        
                        // Finally, set lookup load complete to allow rendering to complete
                        this.setState({ products: newProducts, lookupDetails: json[0], isLookupDetailsLoading: false });

                    });


            });


        // Get Product Images
        body= {
            "procname" : "procProductMediaSelectByPURL",
            "paramcount" : "2",
            "param1" : params.purl,
            "param2" : "IMG"
        }
        await getDataJSON(body)
        .then(json => this.setState({ productImages: json[0], isProductImagesLoading: false }));

        // Get Product Videos
        body= {
            "procname" : "procProductMediaSelectByPURL",
            "paramcount" : "2",
            "param1" : params.purl,
            "param2" : "VID"
        }
        await getDataJSON(body)
        .then(json => this.setState({ productVideos: json[0], isProductVideosLoading: false }));

    }

    async handleSubmit(event) {
        event.preventDefault();
        
        let invalidCount = 0;
        let invalidQACount = 0;

        let ContactJSON = {"ContactID":this.state.clients["0"].ContactID,"EventID":this.state.clients["0"].EventID,"PURL":this.state.clients["0"].PURL,"ResponseSource":this.state.responseSource};
        let prds = [...this.state.products];
        let evtQsts = [...this.state.eventQuestions];
        let newDeclinedProducts = [];
        let questionAnswers = [];
        //let questionAnswersTest = [];

        let tempCombinedJSON = {};
        let combinedJSON = [];

        prds.forEach (prd => {
            // Add a record to DeclinedProduct indication for each product
            let tempDeclinedProduct = {};
            tempDeclinedProduct["ItemProductID"] = prd.ProductID;
            if (this[`chkboxDeclineProduct${prd.ProductID}`].defaultChecked === true) {
                tempDeclinedProduct["ItemHasNoProduct"] = 1;
            } else {
                tempDeclinedProduct["ItemHasNoProduct"] = 0;
            }
            newDeclinedProducts.push(tempDeclinedProduct);

            // Mark count of invalid products due to missing entries and no check box on "No product"
            if (this[`chkboxDeclineProduct${prd.ProductID}`].defaultChecked === false && prd.AddedItemCount === 0) {
                invalidCount = invalidCount + 1;
            }
        }); 

        
        // TODO 
        // Which set represents configured Questions? --> eventQuestions
        // Which set represents the user-provided Question Answers? --> questionAnswers, established in questionAnswers.push using [`textAnswer1`]

        let questionAnswersTest = [
            {position : 1, answer : this[`textAnswer1`] != null ? this[`textAnswer1`].value : (this[`dropAnswer1`] != null ? this[`dropAnswer1`].value : "")}, 
            {position : 2, answer : this[`textAnswer2`] != null ? this[`textAnswer2`].value : (this[`dropAnswer2`] != null ? this[`dropAnswer2`].value : "")}, 
            {position : 3, answer : this[`textAnswer3`] != null ? this[`textAnswer3`].value : (this[`dropAnswer3`] != null ? this[`dropAnswer3`].value : "")}, 
            {position : 4, answer : this[`textAnswer4`] != null ? this[`textAnswer4`].value : (this[`dropAnswer4`] != null ? this[`dropAnswer4`].value : "")}, 
            {position : 5, answer : this[`textAnswer5`] != null ? this[`textAnswer5`].value : (this[`dropAnswer5`] != null ? this[`dropAnswer5`].value : "")}, 
            {position : 6, answer : this[`textAnswer6`] != null ? this[`textAnswer6`].value : (this[`dropAnswer6`] != null ? this[`dropAnswer6`].value : "")}, 
            {position : 7, answer : this[`textAnswer7`] != null ? this[`textAnswer7`].value : (this[`dropAnswer7`] != null ? this[`dropAnswer7`].value : "")}, 
            {position : 8, answer : this[`textAnswer8`] != null ? this[`textAnswer8`].value : (this[`dropAnswer8`] != null ? this[`dropAnswer8`].value : "")}, 
            {position : 9, answer : this[`textAnswer9`] != null ? this[`textAnswer9`].value : (this[`dropAnswer9`] != null ? this[`dropAnswer9`].value : "")}, 
            {position : 10, answer : this[`textAnswer10`] != null ? this[`textAnswer10`].value : (this[`dropAnswer10`] != null ? this[`dropAnswer10`].value : "")} 
        ];


        //Perform a loop to inspect each QuestionAnswer matched to its QuestionMin and QuestionMax - set invalidCount = invalidCount + 1; if invalid
        evtQsts.forEach (qst => {
            // only do work where questiontype = TX
            //console.log("Question " + qst.QuestionOrder + "- " + " - " +qst.UsageTypeCode + " - " + qst.Question + " - Min = " + qst.MinLen + " - Max = " + qst.MaxLen)
            let ans = questionAnswersTest.find( ({position}) => position === qst.QuestionOrder);
            //console.log("Answer " + ans.answer);

            //assume false
            qst.IsInvalid = false;
            qst.IsMissing = false;
            let invalidFlag = false;

            if (qst.UsageTypeCode === 'DD') {
                if (qst.IsRequired && parseInt(ans.answer.length,10) < 1) {
                    qst.IsMissing = true;
                    invalidFlag = true;
                } 
            }
            else if (qst.UsageTypeCode === 'TX') {
                //console.log("Answer length = " + ans.answer.length + " - Min = " + qst.MinLen + " - Max = " + qst.MaxLen);
                if (parseInt(ans.answer.length,10) < parseInt(qst.MinLen,10) || parseInt(ans.answer.length,10) > parseInt(qst.MaxLen,10)){
                    //console.log("Invalid");
                    //Assign IsInvalid to later push back to the state
                    qst.IsInvalid = true;
                    //Record an invalid answer length exists
                    invalidFlag = true;
                } 
                
                if (qst.IsRequired && parseInt(ans.answer.length,10) < 1) {
                    qst.IsMissing = true;
                    invalidFlag = true;
                } 
            }
            //increment invalid answer tracking
            invalidQACount = invalidFlag ? invalidQACount + 1 : invalidQACount;
        });

        questionAnswers.push(
            {
                "Answer1" : this[`textAnswer1`] != null ? this[`textAnswer1`].value : (this[`dropAnswer1`] != null ? this[`dropAnswer1`].value : ""), 
                "Answer2" : this[`textAnswer2`] != null ? this[`textAnswer2`].value : (this[`dropAnswer2`] != null ? this[`dropAnswer2`].value : ""),
                "Answer3" : this[`textAnswer3`] != null ? this[`textAnswer3`].value : (this[`dropAnswer3`] != null ? this[`dropAnswer3`].value : ""),
                "Answer4" : this[`textAnswer4`] != null ? this[`textAnswer4`].value : (this[`dropAnswer4`] != null ? this[`dropAnswer4`].value : ""),
                "Answer5" : this[`textAnswer5`] != null ? this[`textAnswer5`].value : (this[`dropAnswer5`] != null ? this[`dropAnswer5`].value : ""),
                "Answer6" : this[`textAnswer6`] != null ? this[`textAnswer6`].value : (this[`dropAnswer6`] != null ? this[`dropAnswer6`].value : ""),
                "Answer7" : this[`textAnswer7`] != null ? this[`textAnswer7`].value : (this[`dropAnswer7`] != null ? this[`dropAnswer7`].value : ""),
                "Answer8" : this[`textAnswer8`] != null ? this[`textAnswer8`].value : (this[`dropAnswer8`] != null ? this[`dropAnswer8`].value : ""),
                "Answer9" : this[`textAnswer9`] != null ? this[`textAnswer9`].value : (this[`dropAnswer9`] != null ? this[`dropAnswer9`].value : ""),
                "Answer10" : this[`textAnswer10`] != null ? this[`textAnswer10`].value : (this[`dropAnswer10`] != null ? this[`dropAnswer10`].value : "")
            }
        );

        tempCombinedJSON["Contact"] = ContactJSON;
        tempCombinedJSON["ProductResponses"] = this.state.addedItems;
        tempCombinedJSON["ProductDeclines"] = newDeclinedProducts;
        tempCombinedJSON["QuestionResponses"] = questionAnswers[0];

        combinedJSON.push(tempCombinedJSON);
        combinedJSON = combinedJSON[0];
        //alert(JSON.stringify(combinedJSON));

        if (invalidCount > 0 || invalidQACount > 0) {
            this.setState({invalidSubmitCount: invalidCount, invalidQuestionAnswerCount: invalidQACount, eventQuestions: evtQsts, validated: true});
        } else {
            this.setState({ isSubmitting: true});

            // Submit the user response data for persistance
            let body= combinedJSON;
            await getDataJSON(body,'submitresponse')
            .then(json => {
                //console.log('Render Called');
                this.setState({ redirectToReferrer: true, isSubmitting: false});
                }
            );
        }
    }

    clearResponseFields(id) {
        let prds = [...this.state.products];
        let idx = prds.findIndex(el => el.ProductID === id);
        prds[idx].IsModelNumberEmptyYN = 0;
        prds[idx].IsSerialNumberYN = 0;
        prds[idx].IsBatchLotYN = 0;
        prds[idx].IsNDCYN = 0;
        prds[idx].IsUPCYN = 0;
        prds[idx].IsPartNumberYN = 0;
        prds[idx].IsExpDateYN = 0;
        prds[idx].IsDateCodeYN = 0;
        prds[idx].IsQuantityYN = 0;
        prds[idx].IsMissingValuesYN = 0;
        prds[idx].IsQuantityNonNumericYN = 0;

        //Text fields
        if (this[`textModelNumber${id}`] != null) { this[`textModelNumber${id}`].value = "";} 
        if (this[`textSerialNumber${id}`] != null) { this[`textSerialNumber${id}`].value = "";}
        if (this[`textBatchLot${id}`] != null) { this[`textBatchLot${id}`].value = "";}
        if (this[`textNDC${id}`] != null) { this[`textNDC${id}`].value = "";}
        if (this[`textUPC${id}`] != null) { this[`textUPC${id}`].value = "";}
        if (this[`textPartNumber${id}`] != null) { this[`textPartNumber${id}`].value = "";}
        if (this[`textExpDate${id}`] != null) { this[`textExpDate${id}`].value = "";}
        if (this[`textDateCode${id}`] != null) { this[`textDateCode${id}`].value = "";}
        if (this[`textQuantity${id}`] != null) { this[`textQuantity${id}`].value = "";}

        //Drop Down fields
        if (this[`dropModelNumber${id}`] != null) { this[`dropModelNumber${id}`].value = "";} 
        if (this[`dropSerialNumber${id}`] != null) { this[`dropSerialNumber${id}`].value = "";}
        if (this[`dropBatchLot${id}`] != null) { this[`dropBatchLot${id}`].value = "";}
        if (this[`dropNDC${id}`] != null) { this[`dropNDC${id}`].value = "";}
        if (this[`dropUPC${id}`] != null) { this[`dropUPC${id}`].value = "";}
        if (this[`dropPartNumber${id}`] != null) { this[`dropPartNumber${id}`].value = "";}
        if (this[`dropExpDate${id}`] != null) { this[`dropExpDate${id}`].value = "";}
        if (this[`dropDateCode${id}`] != null) { this[`dropDateCode${id}`].value = "";}
        if (this[`dropQuantity${id}`] != null) { this[`dropQuantity${id}`].value = "";}

        this.setState({products: prds})
    }

    validateResponseFields(id) {

        let invalidCount = 0;
        let prds = [...this.state.products];
        let idx = prds.findIndex(el => el.ProductID === id);

        //Text fields
        if ((this[`textModelNumber${id}`] != null && this[`textModelNumber${id}`].value === "") ||
            (this[`dropModelNumber${id}`] != null && this[`dropModelNumber${id}`].value === "")) { 
            prds[idx].IsModelNumberEmptyYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsModelNumberEmptyYN = 0;}
        if ((this[`textSerialNumber${id}`] != null && this[`textSerialNumber${id}`].value === "") || 
            (this[`dropSerialNumber${id}`] != null && this[`dropSerialNumber${id}`].value === "")) { 
            prds[idx].IsSerialNumberYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsSerialNumberYN = 0;}
        if ((this[`textBatchLot${id}`] != null && this[`textBatchLot${id}`].value === "") || 
            (this[`dropBatchLot${id}`] != null && this[`dropBatchLot${id}`].value === "")) { 
            prds[idx].IsBatchLotYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsBatchLotYN = 0;}
        if ((this[`textNDC${id}`] != null && this[`textNDC${id}`].value === "") || 
            (this[`dropNDC${id}`] != null && this[`dropNDC${id}`].value === "")) { 
            prds[idx].IsNDCYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsNDCYN = 0;}
        if ((this[`textUPC${id}`] != null && this[`textUPC${id}`].value === "") || 
            (this[`dropUPC${id}`] != null && this[`dropUPC${id}`].value === "")) { 
            prds[idx].IsUPCYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsUPCYN = 0;}
        if ((this[`textPartNumber${id}`] != null && this[`textPartNumber${id}`].value === "") || 
            (this[`dropPartNumber${id}`] != null && this[`dropPartNumber${id}`].value === "")) { 
            prds[idx].IsPartNumberYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsPartNumberYN = 0;}
        if ((this[`textExpDate${id}`] != null && this[`textExpDate${id}`].value === "") || 
            (this[`dropExpDate${id}`] != null && this[`dropExpDate${id}`].value === "")) { 
            prds[idx].IsExpDateYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsExpDateYN = 0;}
        if ((this[`textDateCode${id}`] != null && this[`textDateCode${id}`].value === "") || 
            (this[`dropDateCode${id}`] != null && this[`dropDateCode${id}`].value === "")) { 
            prds[idx].IsDateCodeYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsDateCodeYN = 0;}
        if ((this[`textQuantity${id}`] != null && this[`textQuantity${id}`].value === "") || 
            (this[`dropQuantity${id}`] != null && this[`dropQuantity${id}`].value === "")) { 
            prds[idx].IsQuantityYN = 1;
            invalidCount = invalidCount + 1;
        } else {prds[idx].IsQuantityYN = 0;}

        prds[idx].IsMissingValuesYN = (invalidCount > 0 ? 1 : 0);

        if ((this[`textQuantity${id}`] != null && !isPosInt(this[`textQuantity${id}`].value)) || 
            (this[`dropQuantity${id}`] != null && !isPosInt(this[`dropQuantity${id}`].value ))) { 
                prds[idx].IsQuantityNonNumericYN = 1;
                invalidCount = invalidCount + 1;
        } else {prds[idx].IsQuantityNonNumericYN = 0;}

        this.setState({products: prds})
        return invalidCount;
    }

    handleAddResponseItem(id) {
        // Add Response Items
        let product = (this.state.products.filter(p => {return p.ProductID === id }))[0];
        let origAddedItems = this.state.addedItems;
        let newAddedItemCount = product.AddedItemCount + 1;

        let invalid = this.validateResponseFields(id);
        if (invalid === 0)    
        {
            origAddedItems.push(
                {
                    "ItemOrderNumber" : newAddedItemCount, 
                    "ItemProductID" : id, 
                    "ItemDescription" : product.ProductName, 
                    "ItemModel" : this[`textModelNumber${id}`] != null ? this[`textModelNumber${id}`].value : (this[`dropModelNumber${id}`] != null ? this[`dropModelNumber${id}`].value : ""), 
                    "ItemSerialNumber" : this[`textSerialNumber${id}`] != null ? this[`textSerialNumber${id}`].value : (this[`dropSerialNumber${id}`] != null ? this[`dropSerialNumber${id}`].value : ""),
                    "ItemBatchLot" : this[`textBatchLot${id}`] != null ? this[`textBatchLot${id}`].value : (this[`dropBatchLot${id}`] != null ? this[`dropBatchLot${id}`].value : ""),
                    "ItemNDC" : this[`textNDC${id}`] != null ? this[`textNDC${id}`].value : (this[`dropNDC${id}`] != null ? this[`dropNDC${id}`].value : ""),
                    "ItemUPC" : this[`textUPC${id}`] != null ? this[`textUPC${id}`].value : (this[`dropUPC${id}`] != null ? this[`dropUPC${id}`].value : ""),
                    "ItemPartNumber" : this[`textPartNumber${id}`] != null ? this[`textPartNumber${id}`].value : (this[`dropPartNumber${id}`] != null ? this[`dropPartNumber${id}`].value : ""),
                    "ItemExpDate" : this[`textExpDate${id}`] != null ? this[`textExpDate${id}`].value : (this[`dropExpDate${id}`] != null ? this[`dropExpDate${id}`].value : ""),
                    "ItemDateCode" : this[`textDateCode${id}`] != null ? this[`textDateCode${id}`].value : (this[`dropDateCode${id}`] != null ? this[`dropDateCode${id}`].value : ""),
                    "ItemQty" : this[`textQuantity${id}`] != null ? this[`textQuantity${id}`].value : (this[`dropQuantity${id}`] != null ? this[`dropQuantity${id}`].value : ""),
                }
            );
            let newItems = origAddedItems;

        // Clear previous entries
        this.clearResponseFields(id);

        // Update products with new item count
        let prds = [...this.state.products];
        let idx = prds.findIndex(el => el.ProductID === id);
        prds[idx].AddedItemCount = newAddedItemCount;

        // Set state and finish
        this.setState({addedItems: newItems, products: prds});
        toast.success("Product Entry has been successfully added. Please click 'Submit' when finished.");
        }

    }

    removeResponseItem(id) {
        // id = ItemOrderNumber in session for removal. Do not reduce the index counter, or things will get weird
        let origAddedItems = [...this.state.addedItems];
        let indexForRemoval = origAddedItems.findIndex(x => x.ItemOrderNumber === id);
        let item = origAddedItems[indexForRemoval];

        // Reduce Response Item count for product that matches selected AddedItem
        let product = (this.state.products.filter(p => {return p.ProductID === item.ItemProductID }))[0];
        let newAddedItemCount = product.AddedItemCount - 1;        

        // Update products with new item count
        let prds = [...this.state.products];
        let idx = prds.findIndex(el => el.ProductID === item.ItemProductID);
        prds[idx].AddedItemCount = newAddedItemCount;

        // Remove Item from AddedItems and update the state of both products and addedItems
        origAddedItems.splice(indexForRemoval, 1) 
        this.setState({addedItems: origAddedItems, products: prds});
        toast.info('Product Entry has been successfully removed.');
    }

    renderEventQuestionLookupOptions(qst) {
        if (qst.Lookup === null) {
            return;
        } else {
            return qst.Lookup.map(lkup => {
                    return(
                            <option key={lkup.LookupDetailID}>{lkup.LValue}</option>
                    )
                }
            )
        }
    }

    renderQuestions() {
        let q01 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 1 }))[0];
        let q02 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 2 }))[0];
        let q03 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 3 }))[0];
        let q04 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 4 }))[0];
        let q05 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 5 }))[0];
        let q06 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 6 }))[0];
        let q07 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 7 }))[0];
        let q08 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 8 }))[0];
        let q09 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 9 }))[0];
        let q10 = (this.state.eventQuestions.filter(eq => {return eq.QuestionOrder === 10 }))[0];

        return (
            this.state.eventQuestions.length > 0 &&  //This will only allow rendoring if questions exist
            <div>
                <div style={{clear: 'both', paddingTop:'40px'}}>QUESTIONS</div>    
                <div style={{clear: 'both', width: '100%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                <div>
                <br/>
                <Form noValidate validated={this.state.validated}>
                    {q01 && q01.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion1">
                        <Form.Label>{q01.Question}</Form.Label>
                        {q01.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q01.IsMissing} isInvalid={q01.IsMissing} ref={input=>{this[`dropAnswer1`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q01)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q01.IsMissing||q01.IsInvalid} isInvalid={q01.IsMissing||q01.IsInvalid} maxLength={q01.MaxLen} ref={input=>{this[`textAnswer1`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q01.MinLen} and {q01.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }
                    {q02 && q02.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion2">
                        <Form.Label>{q02.Question}</Form.Label>
                        {q02.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q02.IsMissing} isInvalid={q02.IsMissing} ref={input=>{this[`dropAnswer2`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q02)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q02.IsMissing||q02.IsInvalid} isInvalid={q02.IsMissing||q02.IsInvalid} maxLength={q02.MaxLen} ref={input=>{this[`textAnswer2`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q02.MinLen} and {q02.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }
                    {q03 && q03.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion3">
                        <Form.Label>{q03.Question}</Form.Label>
                        {q03.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q03.IsMissing} isInvalid={q03.IsMissing} ref={input=>{this[`dropAnswer3`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q03)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                        ) : (
                            <div>
                                <Form.Control required={q03.IsMissing||q03.IsInvalid} isInvalid={q03.IsMissing||q03.IsInvalid} maxLength={q03.MaxLen} ref={input=>{this[`textAnswer3`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q03.MinLen} and {q03.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                    {q04 && q04.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion4">
                        <Form.Label>{q04.Question}</Form.Label>
                        {q04.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q04.IsMissing} isInvalid={q04.IsMissing} ref={input=>{this[`dropAnswer4`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q04)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q04.IsMissing||q04.IsInvalid} isInvalid={q04.IsMissing||q04.IsInvalid} maxLength={q04.MaxLen} ref={input=>{this[`textAnswer4`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q04.MinLen} and {q04.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }
                    {q05 && q05.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion5">
                        <Form.Label>{q05.Question}</Form.Label>
                        {q05.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q05.IsMissing} isInvalid={q05.IsMissing} ref={input=>{this[`dropAnswer5`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q05)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q05.IsMissing||q05.IsInvalid} isInvalid={q05.IsMissing||q05.IsInvalid} maxLength={q05.MaxLen} ref={input=>{this[`textAnswer5`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q05.MinLen} and {q05.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                    {q06 && q06.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion6">
                        <Form.Label>{q06.Question}</Form.Label>
                        {q06.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q06.IsMissing} isInvalid={q06.IsMissing} ref={input=>{this[`dropAnswer6`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q06)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q06.IsMissing||q06.IsInvalid} isInvalid={q06.IsMissing||q06.IsInvalid} maxLength={q06.MaxLen} ref={input=>{this[`textAnswer6`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q06.MinLen} and {q06.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                    {q07 && q07.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion7">
                        <Form.Label>{q07.Question}</Form.Label>
                        {q07.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q07.IsMissing} isInvalid={q07.IsMissing} ref={input=>{this[`dropAnswer7`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q07)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q07.IsMissing||q07.IsInvalid} isInvalid={q07.IsMissing||q07.IsInvalid} maxLength={q07.MaxLen} ref={input=>{this[`textAnswer7`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q07.MinLen} and {q07.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                    {q08 && q08.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion8">
                        <Form.Label>{q08.Question}</Form.Label>
                        {q08.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q08.IsMissing} isInvalid={q08.IsMissing} ref={input=>{this[`dropAnswer8`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q08)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q08.IsMissing||q08.IsInvalid} isInvalid={q08.IsMissing||q08.IsInvalid} maxLength={q08.MaxLen} ref={input=>{this[`textAnswer8`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q08.MinLen} and {q08.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                    {q09 && q09.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion9">
                        <Form.Label>{q09.Question}</Form.Label>
                        {q09.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q09.IsMissing} isInvalid={q09.IsMissing} ref={input=>{this[`dropAnswer9`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q09)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q09.IsMissing||q09.IsInvalid} isInvalid={q09.IsMissing||q09.IsInvalid} maxLength={q09.MaxLen} ref={input=>{this[`textAnswer9`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q09.MinLen} and {q09.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                    {q10 && q10.UsageTypeCode !== 'NU' &&
                    <Form.Group as={Col} controlId="formGridQuestion10">
                        <Form.Label>{q10.Question}</Form.Label>
                        {q10.UsageTypeCode === 'DD' ? (
                            <div>                            
                                <Form.Control required={q10.IsMissing} isInvalid={q10.IsMissing} ref={input=>{this[`dropAnswer10`] = input;}} as="select">
                                <option></option>
                                {this.renderEventQuestionLookupOptions(q10)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Required entry. Please make a selection.
                                </Form.Control.Feedback>
                            </div>
                    ) : (
                            <div>
                                <Form.Control required={q10.IsMissing||q10.IsInvalid} isInvalid={q10.IsMissing||q10.IsInvalid} maxLength={q10.MaxLen} ref={input=>{this[`textAnswer10`] = input;}} placeholder="" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a value between {q10.MinLen} and {q10.MaxLen} characters.
                                </Form.Control.Feedback>
                            </div>
                        )}
                    </Form.Group>
                    }

                </Form>
                </div>
            </div>
        )
    }

    renderProductsEntered(product) {
        let addedItemsByProduct = (this.state.addedItems.filter(item => {return parseInt(item.ItemProductID,10) === product.ProductID}));

        return (
        <div>
            <CardDeck> 
                {
                    addedItemsByProduct.map(item => {
                        return(
                        <Card key={item.ItemOrderNumber} bg="secondary" text="white" className="text-center" 
                            style={{minWidth:'360px', maxWidth:'400px'}}>
                            <Card.Body>
                                <Card.Title >Product Entry - {item.ItemDescription}</Card.Title>   {/* #{item.ItemOrderNumber} */}

                                {item.ItemModel !== '' &&
                                    <div>Model = {item.ItemModel}<br/></div>
                                }
                                {item.ItemSerialNumber !== '' &&
                                    <div>Serial Number = {item.ItemSerialNumber}<br/></div>
                                }
                                {item.ItemBatchLot !== '' &&
                                    <div>BatchLot = {item.ItemBatchLot}<br/></div>
                                }
                                {item.ItemNDC !== '' &&
                                    <div>NDC = {item.ItemNDC}<br/></div>
                                }
                                {item.ItemUPC !== '' &&
                                    <div>UPC = {item.ItemUPC}<br/></div>
                                }
                                {item.ItemPartNumber !== '' &&
                                    <div>PartNumber = {item.ItemPartNumber}<br/></div>
                                }
                                {item.ItemExpDate !== '' &&
                                    <div>ExpDate = {item.ItemExpDate}<br/></div>
                                }
                                {item.ItemDateCode !== '' &&
                                    <div>DateCode = {item.ItemDateCode}<br/></div>
                                }
                                {item.ItemQty !== '' &&
                                    <div>Quantity = {item.ItemQty}<br/></div>
                                }
                                <br/>
                                <MyButton id={item.ItemOrderNumber} key={item.ItemOrderNumber} 
                                    variant="primary" type="button" onClick={this.removeResponseItem}>Remove</MyButton>
                            </Card.Body>
                        </Card>
                        )    
                    })
                }
            </CardDeck> 
        </div>
        )
    }

    toggleDeclineProduct(product) {
        let prds = [...this.state.products];
        let idx = prds.findIndex(el => el.ProductID === product.ProductID);
        prds[idx].IsProductDeclinedYN = !prds[idx].IsProductDeclinedYN;
        this.clearResponseFields(product.ProductID);
        this.setState({products: prds})
    }

    renderLookupOptions(product, lookupType) {
        return product[lookupType].map(lkup => {
                return(
                        <option key={lkup.LookupDetailID}>{lkup.LValue}</option>
                )
            }
        )
    }

    renderProductEntry(product) {
        return (
    <div style={{border: '0px solid black', paddingRight: '15px'}}>
    <br/>
    <table style={{ align: 'center', width:'100%', marginLeft: '0px', marginRight: '0px'}}>
        <thead>
            <tr>
                <td>
                    <Form>
                        <Form.Group as={Col} controlId={"formGridHeader" + product.ProductID}>
                            <Form.Label>
                                <b>Product Entry</b>                                                 
                            </Form.Label>
                            <div style={{clear: 'both', width: '100%', height: '1px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                            <Accordion defaultActiveKey="1">
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Click here to show/hide helpful instructions.
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>This form provides you the ability to enter identifying details associated with your product. Each field is required and needs to be completed.
                                <br/><br/> After providing information for one set of product, click Add to create it as an entry. You may repeat for as many combinations of product information as required.
                                <br/><br/> If you do not have any of the product indicated, please indicate by checking the "I have no product" checkbox.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            </Accordion> 
                            <div style={{clear: 'both', width: '100%', height: '1px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                        </Form.Group>
                    </Form>
                    
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                <Form>
                    <Form.Group as={Col} controlId={"formGridDeclineProduct" + product.ProductID}>
                        <Form.Check 
                            disabled={product.AddedItemCount > 0}
                            type="checkbox" 
                            label="I have no product." 
                            defaultChecked={product.IsProductDeclinedYN} 
                            ref={input=>{this[`chkboxDeclineProduct${product.ProductID}`] = input;}}
                            onClick={() => { this.toggleDeclineProduct(product) }} />
                    </Form.Group>
                    
                    {product.ModelUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridModel" + product.ProductID}>
                            <Form.Label style={{color : product.IsModelNumberEmptyYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Model</Form.Label>
                            {product.ModelUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsModelNumberEmptyYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropModelNumber${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'ModelLookup')}
                                </Form.Control>
                            ) : (
                                 <Form.Control style={{borderColor : product.IsModelNumberEmptyYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textModelNumber${product.ProductID}`] = input;}} placeholder="" />
                            )}
                        </Form.Group>
                    }

                    {product.SerialUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridSerialNumber" + product.ProductID}>
                            <Form.Label style={{color : product.IsSerialNumberYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Serial Number</Form.Label>
                            {product.SerialUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsSerialNumberYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropSerialNumber${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'SerialLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsSerialNumberYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textSerialNumber${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.BatchLotUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridBatchLot" + product.ProductID}>
                            <Form.Label style={{color : product.IsBatchLotYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Batch/Lot Number</Form.Label>
                            {product.BatchLotUsageTypeCode === 'DD' ? (
                                <Form.Control hidden={product.IsBatchLotYN} ref={input=>{this[`dropBatchLot${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'BatchLotLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsBatchLotYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textBatchLot${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.NDCUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridNDC" + product.ProductID}>
                            <Form.Label style={{color : product.IsNDCYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>NDC</Form.Label>
                            {product.NDCUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsNDCYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropNDC${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'NDCLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsNDCYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textNDC${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.UPCUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridUPC" + product.ProductID}>
                            <Form.Label style={{color : product.IsUPCYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>UPC</Form.Label>
                            {product.UPCUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsUPCYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropUPC${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'UPCLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsUPCYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textUPC${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.PartNumberUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridPartNumber" + product.ProductID}>
                            <Form.Label style={{color : product.IsPartNumberYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Part Number</Form.Label>
                            {product.PartNumberUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsPartNumberYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropPartNumber${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'PartNumberLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsPartNumberYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textPartNumber${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.ExpDateUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridExpDate" + product.ProductID}>
                            <Form.Label style={{color : product.IsExpDateYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Expiration Date</Form.Label>
                            {product.ExpDateUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsExpDateYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropExpDate${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'ExpDateLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsExpDateYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textExpDate${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.DateCodeUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridDateCode" + product.ProductID}>
                            <Form.Label style={{color : product.IsDateCodeYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Date Code</Form.Label>
                            {product.DateCodeUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsDateCodeYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropDateCode${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'DateCodeLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsDateCodeYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textDateCode${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }

                    {product.QtyUsageTypeCode !== 'NU' &&
                        <Form.Group as={Col} controlId={"formGridQuantity" + product.ProductID}>
                            <Form.Label style={{color : product.IsQuantityYN || product.IsQuantityNonNumericYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN}>Quantity</Form.Label>
                            {product.QtyUsageTypeCode === 'DD' ? (
                                <Form.Control style={{borderColor : product.IsQuantityYN || product.IsQuantityNonNumericYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`dropQuantity${product.ProductID}`] = input;}} as="select">
                                <option></option>
                                {this.renderLookupOptions(product,'QtyLookup')}
                                </Form.Control>
                            ) : (
                                <Form.Control style={{borderColor : product.IsQuantityYN || product.IsQuantityNonNumericYN ? '#dc3545' : ''}} hidden={product.IsProductDeclinedYN} ref={input=>{this[`textQuantity${product.ProductID}`] = input;}} placeholder="" />
                            )}        </Form.Group>
                    }
                        <div align='center' style={{paddingTop: '10px'}}>
                            <table style={{width:'350px'}}>
                                <tbody>
                                    <tr>
                                        <td style={{paddingLeft:'20px', paddingRight:'20px'}}>
                                        <MyButton hidden={product.IsProductDeclinedYN} id={product.ProductID} type="button" variant="primary" onClick={this.clearResponseFields}>
                                            Clear
                                        </MyButton>
                                        </td>
                                        <td>
                                        <MyButton hidden={product.IsProductDeclinedYN} id={product.ProductID} type="button" variant="primary" onClick={this.handleAddResponseItem}>
                                            Add
                                        </MyButton>
                                        </td>

                                    </tr>
                                    <tr align='center'>
                                        <td hidden={!product.IsMissingValuesYN} style={{color:'#dc3545', paddingTop:'10px'}} colSpan='2'><b>Please provide values for all fields above.</b></td>
                                        <td></td>
                                    </tr>
                                    <tr align='center'>
                                        <td hidden={!product.IsQuantityNonNumericYN} style={{color:'#dc3545', paddingTop:'10px'}} colSpan='2'><b>Quantity must be a positive numeric value.</b></td>
                                        <td></td>
                                    </tr>
                                </tbody>                            
                            </table>
                        </div>
                        <br/>
                    </Form>

                </td>
            </tr>
        </tbody>
    </table>
    </div>        

        )
    }

    renderProductVideoRow(product) {
        let filteredProductVideos = (this.state.productVideos.filter(pvid => {return pvid.ProductID === product.ProductID }));
        let sortedfilteredProductVideos = filteredProductVideos.sort(function(a, b) {return a.SortOrder - b.SortOrder});

        return sortedfilteredProductVideos.map(pvid => {
                return(
                    <div key={pvid.MediaID} style={{textAlign:'center'}}>
                    <video key={pvid.MediaID} style={{width:'100%', padding:'15px'}} controls>  {/*width='360px'*/}
                        <source src={"https://" + config.s3.BUCKET + ".s3.amazonaws.com/" + 
                            "public/RRRProductMedia/" + pvid.MediaURL} type="video/mp4" />
                    </video>
                    </div>
                )
            }
        )
    }

    renderProductImageRow(product) {

        let filteredProductImages = (this.state.productImages.filter(pimg => {return pimg.ProductID === product.ProductID }));
        let sortedfilteredProductImages = filteredProductImages.sort(function(a, b) {return a.SortOrder - b.SortOrder});

        return sortedfilteredProductImages.map(pimg => {
                return(
                    <Carousel.Item key={pimg.MediaID} style={{height: '185px',maxWidth:'389px',backgroundColor:'lightgray', paddingLeft:'0px', paddingRight:'0px', textAlign:'center'}}>   {/*float: "left", */}
                        <img  border="1px solid black" height="150px" 
                            src={"https://" + config.s3.BUCKET + ".s3.amazonaws.com/" + 
                                "public/RRRProductMedia/" + pimg.MediaURL}  alt='Product Media'
                                onClick={(e) => {e.preventDefault(); this.setState({ modalShow: true, 
                                    modalImage: "https://" + config.s3.BUCKET + ".s3.amazonaws.com/public/RRRProductMedia/" + pimg.MediaURL},function(){console.log(this.state.modalShow);});}}
                        />
                        <br/>
                       {/*  <Button variant="primary" onClick={(e) => {e.preventDefault(); this.setState({ modalShow: true, 
                            modalImage: "https://srcl-selfserve-eventcontent-dev.s3.amazonaws.com/RRRProductMedia/" + pimg.MediaURL},function(){console.log(this.state.modalShow);});}}>
                            Zoom
                        </Button> */}
                    </Carousel.Item>
                )
            }
        )
    }

    renderProductInfoAndMedia(product) {
        return (
            <div style={{border:'0px solid black'}}>
                <div style={{ paddingRight: '15px'}}>
                    <br/>
                    <table style={{ width:'94%', align: 'center', marginLeft: 'auto', marginRight: 'auto', border: '0px solid red'}}>
                        <thead>
                            <tr>
                                <td class="ql-editor">
                                    <b>{product.ProductName}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div style={{clear: 'both', marginTop:'6px', width: '100%', height: '1px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="ql-editor">{ReactHtmlParser(product.ProductDescription)}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div>
                    <br/>
                    <table style={{width:'94%',align:'center'}}> {/* was 15px padding  border: '0px solid black'*/}
                        <tbody>
                            <tr style={{width:'100%', color: 'white', border: '1px solid lightgray', verticalAlign: 'middle'}}>
                                 <td style={{verticalAlign: 'middle', height:'200px'}}> {/* was 15px padding */}
                                 <Carousel style={{height: '215px', padding:'15px'}}>
                                 {this.renderProductImageRow(product)}
                                 </Carousel>
                                </td> 
                            </tr>
                            <tr style={{width:'100%', border: '1px solid lightgray'}}>
                                <td>
                                {this.renderProductVideoRow(product)}
                                </td>
                            </tr>
                            {/* <tr><td>&nbsp;</td></tr> */}
                        </tbody>
                    </table>
                </div>
            </div>       
        )
    }

    renderProductCorrals() {
        return this.state.products.map((product, index) => {
            return (
                // This display inline-block helped keep the corral together, but has a big space to the right.
                <div style={{width:'100%', display: 'inline-block', paddingTop: '30px'}} key={product.ProductID}>
                    <div>
                    <div style={{}}>PRODUCT {index+1}</div>    
                    <div style={{width: '100%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                        <div style={{display: 'inline-block', verticalAlign: 'top', float: 'left',  paddingRight: '0px', width:'100%', maxWidth:'450px'}}>  {/*maxWidth: '400px',*/}
                            <div> 
                                {this.renderProductInfoAndMedia(product)}
                            </div>
                        </div>
                        <div style={{display: 'inline-block', float: 'left', verticalAlign: 'top',  width:'100%', maxWidth:'450px'}}> {/*width: '50%',  minWidth:'360px'*/}
                            {this.renderProductEntry(product)}
                        </div>
                        <div style={{clear:'both' }} >   {/* display: 'inline-block', float: 'left' */}
                            {this.renderProductsEntered(product)}
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderResponseTitle() {
            return (
    <div>
        <div style={{paddingLeft:'0px', marginTop: "20px"}}><h4 class="ql-editor">{this.state.clients["0"].WebHeaderMsg}</h4> </div>
        <div style={{clear: 'both', width: '100%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
        <table style={{ align: 'left', marginLeft: '10px', marginRight: 'auto', marginTop: '20px',
                                    width: '95%', border: '0px solid black'}}>
            <tbody>
                <tr >
                    <td class="ql-editor">{ReactHtmlParser(this.state.clients["0"].WebDetailedMsg)}</td>
                </tr>
            </tbody>
        </table>
    </div>
            );
    }

    render() {
        const { clients, isClientsLoading, isProductsLoading, isLookupDetailsLoading, isEventQuestionsLoading,
            isProductImagesLoading, isProductVideosLoading, isEventQuestionsLookupLoading, redirectToReferrer, isSubmitting,
             isClientPlanActive, isCampaignActive} = this.state;

        if (!isSubmitting && redirectToReferrer === true) {
            return (
                <Redirect to={{
                            pathname: '/confirmation',
                            state: { purl: this.state.purl }
                        }}
                />
            )
        }
        
        if (isClientsLoading || isProductsLoading || isLookupDetailsLoading || isEventQuestionsLoading || isProductImagesLoading || isProductVideosLoading || isEventQuestionsLookupLoading) {
            return <p>Loading ...</p>;
        }

        if (!isClientsLoading && (isClientPlanActive === 0 || isCampaignActive === 0)) {
            return (
                <Redirect to={{
                            pathname: '/noaction',
                            state: { purl: this.state.purl }
                        }}
                />
            )
        }

        if (clients.length === 0) {
            return <p>No Clients or Contact Unrecognized.</p>
        } 

        return (
            <div style={{padding: '1px', width: '100%'}}  >  {/* backgroundColor: 'lightgrey', */}

            <MyVerticallyCenteredModal
                modalImage={this.state.modalImage}
                show={this.state.modalShow}
                onHide={() => {this.setState({ modalShow: false});}}
            />

            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
            />
                <table style={{ align: 'center', marginLeft: '0px', marginRight: '0px',
                                width: '100%', border: '0px solid black', background: this.state.clients["0"].SiteColor}}>
                    <tbody>
                        <tr>
                            <td>
                            <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                    width: '95%', border: '0px solid black'}}>
                                <tbody>
                                <tr style={{width:'100%', color: this.state.fontColor, border: '0px solid black', verticalAlign: 'middle', background: this.state.clients["0"].SiteColor }} >
                                    <td style={{width:'50%', verticalAlign: 'middle'}}>
                                        {(this.state.clients["0"].LogoURN !== 'undefined' && this.state.clients["0"].LogoURN && this.state.clients["0"].LogoURN.length !== 0) &&
                                        <img  border="0px solid black" height="100px" style={{padding: "10px"}}
                                        src={"https://" + config.s3.BUCKET + ".s3.amazonaws.com/" + 
                                                "public/RRRClientLogo/Event_" + this.state.clients["0"].EventID + 
                                                "_logo.png"}  alt='Campaign Logo'/>
                                        }
                                        <ul style={{display:'inline-block', listStyleType: "none", paddingTop:'10px'}}>
                                            <li style={{height: '24px',fontFamily: 'Ebrima',fontSize:'24px',fontWeight: '500'}}>{this.state.clients["0"].ClientName}</li>
                                        </ul>
                                    </td>
                                    <td style={{width:'50%', align:'right', verticalAlign: 'middle', textAlign:'right'}}>
                                        <ul style={{display:'inline-block', listStyleType: "none", paddingTop:'10px'}}>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].ContactName}</li>
                                            <li style={{height: '15px',fontFamily: 'Ebrima',fontSize:'15px',fontWeight: '500'}}>{this.state.clients["0"].Email}</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                width: '95%', border: '0px solid black'}}>
                    <tbody>
                        <tr>    
                            <td>
                                <div>
                                    {this.renderResponseTitle()}
                                </div>
                                <br/>
                                <div>
                                    {this.renderProductCorrals()}
                                </div>
                                <br/>
                                <div>
                                    {this.renderQuestions()}
                                </div>
                                <br/>
                                <div style={{width: '100%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <Form onSubmit={this.handleSubmit}>
                <div style={{clear:'both', textAlign: 'center', align: 'center'}} align='center'>
                    <table style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto',
                                width: '95%'}}>
                        <tbody>
                            <tr align='center'>
                                <td>
                                <Button variant="primary" type="submit" disabled={this.state.clients["0"].ContactName === "Preview User"}>
                                    <Spinner hidden={!this.state.isSubmitting}
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>&nbsp;Submit&nbsp;</span>
                                </Button>
                                </td>
                            </tr>
                            <tr align='center'>
                                <td hidden={this.state.invalidSubmitCount === 0} style={{color:'#dc3545', paddingTop:'10px'}}><b>Please add entries for each product.<br/>Use <i>"I have no product"</i> if you have none.</b></td>
                            </tr>
                            <tr align='center'>
                                <td hidden={this.state.invalidQuestionAnswerCount === 0} style={{color:'#dc3545', paddingTop:'10px'}}><b>Please enter an answer for each required question.</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <div style={{clear:'both', textAlign: 'center', align: 'center'}}>
                        <table align='center' style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '40px',
                                        width: '65%', border: '1px solid black'}}>
                            <tbody>
                                <tr >
                                    <td class="ql-editor">{ReactHtmlParser(this.state.clients["0"].WebFooterMessage)}</td>
                                </tr>
                                <tr >
                                    <td class="ql-editor">{ReactHtmlParser(this.state.clients["0"].RecallHotline)}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                </Form>
                <br/>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '95%', height: '6px', background: this.state.clients["0"].SiteColor}}>&nbsp;</div>
                <br/>
            </div>
        );
    }
}



export default (App);
